import { Alert } from "react-bootstrap";
import CollegialBodiesProps from "./types";
import InnerMenu from "../../components/innerMenu/InnerMenu";
import Loader from "../../components/loader/Loader";
import { MenuItem } from "../../components/innerMenu/types";
import React from "react";
import { Response } from "../../components/menuPane/types";
import { dashify } from "../../utils";
import { useRouteMatch } from "react-router-dom";
import useTeamsChannel from "../../hooks/useTeamsChannel";

const CollegialBodies: React.FunctionComponent<CollegialBodiesProps> = () => {
  const { data, isSuccess, isLoading } = useTeamsChannel("organi-collegiali");

  const match = useRouteMatch();

  const collegialBodies: MenuItem[] = data?.map((collegialBody: Response) => {
    const teamsLinks: MenuItem[] = collegialBody.teamsLinks.map((link) => ({
      id: link.name,
      label: link.name.toUpperCase(),
      link: link.teamsLink,
    }));

    return {
      id: collegialBody.groupName,
      label: collegialBody.groupName.toUpperCase(),
      link: `${match.path}/${dashify(collegialBody.groupName)}`,
      subMenuItems: teamsLinks,
    };
  });

  return (
    <>
      <p>
        Sono stati creati dei team per permettere lo svolgimento delle riunioni
        degli organi collegiali. L&apos;elenco è disponibile{" "}
        <a href="#elenco_consigli">in fondo a questa pagina</a>. Per ciascun
        Dipartimento, Corso di Studio e Dottorato è stato creato un team privato
        al quale possono accedere esclusivamente i suoi membri. A ciascun team
        sono stati aggiunti automaticamente alcuni membri:
      </p>
      <ul>
        <li>
          per i Dipartimenti: sono stati aggiunti i professori, i ricercatori ed
          il personale tecnico-amministrativo;
        </li>
        <li>
          per i Corsi di Studio: sono stati aggiunti i titolari degli
          insegnamenti del CdS erogati nell&apos;AA 2019/2020, ad esclusione
          degli insegnamenti mutuati da altri CdS;
        </li>
        <li>
          per i Dottorati: sono stati aggiunti i membri del Collegio del 35mo
          ciclo.
        </li>
      </ul>
      <p>All&apos;interno di ciascun team è stato creato un canale privato</p>
      <ul>
        <li>
          per i Dipartimenti il canale si chiama &quot;Consiglio di
          Dipartimento&quot; e vi sono stati aggiunti automaticamente i
          professori ed i ricercatori;
        </li>
        <li>
          per i Corsi di Studio il canale si chiama &quot;Consiglio di Corso di
          Studio&quot; e vi sono stati aggiunti automaticamente tutti i membri
          del team:
        </li>
        <li>
          per i Dottorati il canale si chiama &quot;Collegio 35&quot; e vi sono
          stati aggiunti automaticamente tutti i membri del team.
        </li>
      </ul>
      <p>
        Ulteriori membri (p.e. segretari amministrativi, rappresentanti del
        personale tecnico-amministrativo, rappresentanti degli studenti) possono
        essere aggiunti al team, ed eventualmente ai canali privati, dal
        Direttore di Dipartimento, Presidente di Corso di Studio o Coordinatore
        di Dottorato.
      </p>
      <Alert variant="primary">
        NOTA: Gli utenti possono essere aggiunti al team e, successivamente, ai
        canali privati soltanto se possiedono un account Microsoft con
        l&apos;email di Ateneo (<code>@studenti.unich.it</code> oppure{" "}
        <code>@unich.it</code>).
      </Alert>
      <Alert variant="primary">
        NOTA: E&apos; possibile aggiungere un utente ad un canale privato solo
        se è stato già aggiunto al team. Microsoft Teams a volte impiega anche
        alcune ore per consentire di aggiungere ad un canale un utente che è
        stato appena aggiunto al team. Se riscontrate problemi di questo tipo è
        bene pazientare qualche ora.
      </Alert>
      <p>
        Ciascun Direttore di Dipartimento, Presidente di Corso di Studio,
        Coordinatore di Dottorato è invitato a verificare la correttezza della
        composizione dei team e dei canali.
      </p>
      <p>
        In questa fase le richieste di inserimento pervenute tramite canali
        diversi (p.e Teams) potrebbero essere ignorate.
      </p>
      <p>
        I team degli organi collegiali sono pubblicati alla pagina{" "}
        <a href="https://zeus.unich.it/teledidattica19/#consigli">https://zeus.unich.it/teledidattica19/#consigli</a>.
      </p>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && (
          <div id="elenco_consigli">
            <InnerMenu menuItems={collegialBodies} hasChildren />
          </div>
        )
      )}
    </>
  );
};

export default CollegialBodies;
