import { Alert } from "react-bootstrap";
import HomeProps from "./types";
import { NavLink } from "react-router-dom";
import React from "react";

const Home: React.FunctionComponent<HomeProps> = () => {
  return (
    <>
      <h3> Informazioni sulla didattica 2024/2025 </h3>

     <Alert variant="success">
        Le informazioni sulla modalità di erogazione della didattica per l'a.a. 2024/2025
        sono disponibili in questa{" "}
        <a href="https://www.unich.it/didattica/frequentare/disposizioni-sulle-modalita-di-erogazione-delle-attivita-didattiche-aa">pagina</a>.
      </Alert>

      <h3>Piattaforma di videoconferenza Microsoft</h3>
      <p>
        L'Ateneo utilizza Microsoft Teams come piattaforma di videoconferenza, sia per la didattica a distanza che
	per le riunioni degli organi. Per accedere alla piattaforma Microsoft di Ateneo 
        è necessario avere un account Microsoft collegato all'email di
	Ateneo (@studenti.unich.it oppure @unich.it). Questo vale sia per i docenti che per gli studenti.
	Anche quando si disponga già di un account Microsoft privato collegato ad altra email, è comunque necessario
	crearne uno nuovo collegato alla propria email di Ateneo.
      </p>
      <p>
	Per la creazione dell'account Microsoft consultare la sezione{" "}
        <NavLink to="account">Account Microsoft</NavLink>.
      </p>
      <h3>Installazione pacchetto Microsoft Office</h3>
      <p>
        Il personale UdA e gli studenti possono scaricare gratuitamente il pacchetto
	Microsoft Office (dopo aver creato un account). Le informazioni per scaricare
	il pacchetto sono disponibili nella sezione{" "}
	<NavLink to="account">Account Microsoft</NavLink>.
      </p>
      <h3>Accesso alla didattica on-line</h3>
     
      <p>La teledidattica è erogata tramite la piattaforma Microsoft
        Teams, utilizzabile sia con un browser all&apos;indirizzo{" "}
        <a href="http://teams.microsoft.com">teams.microsoft.com</a> (solo
        Chrome ed Edge sono supportati) sia con
        l&apos;applicazione specifica che si può scaricare da{" "}
        <a href="http://teams.microsoft.com/downloads">
          teams.microsoft.com/downloads
        </a>
        . L&apos;applicazione Microsoft Teams è inoltre già integrata in Office
        365.
      </p>
       <p>
        Si invitano tutti gli studenti, i docenti e il personale
        tecnico-amministrativo a prendere visione delle{" "}
        <a href="https://zeus.unich.it/teledidattica19/doc/didattica_a_distanza_prescrizioni_comportamentali.pdf">
          PRESCRIZIONI COMPORTAMENTALI
        </a>{" "}
        delle attività didattiche a distanza.
      </p>

     <h5>Istruzioni per gli studenti</h5>
      <p>
        Una volta creato l&apos;account, sarà sufficiente effettuare il login sulla
        piattaforma Microsoft Teams ed entrare nel{" "}
	<a href="https://zeus.unich.it/teledidattica19/#docenti">team del docente</a>{" "} (per il ricevimento studenti)
	oppure dell'insegnamento (per le lezioni on-line).
	In prossimità dell&apos;<a href="https://www.unich.it/sua">orario previsto per la
        lezione</a>, gli studenti troveranno un post con il pulsante Partecipa,
        tramite il quale potranno prendere parte alla lezione.
      </p>
      <Alert variant="danger">
        <span className="font-weight-bold"> IMPORTANTE</span>: prima
	di accedere alla lezione, gli studenti
        <span className="font-weight-bold"> DEVONO</span> disabilitare webcam e
        microfono. È consentito allo studente riattivare il microfono nel
        momento in cui vuole porre delle domande al docente.
      </Alert>
      <h5>Istruzioni per i docenti</h5>
      <p>
        Prima di tutto occorre entrare nel team dedicato al proprio
        insegnamento. È sufficiente avviare Teams per vedere tutti i team di
	cui si fa parte.
      </p>
      <Alert variant="danger">
        <span className="font-weight-bold"> IMPORTANTE</span>: i docenti NON
        devono creare i team in maniera autonoma.
      </Alert>
      <p>
        La sezione{" "}
        <NavLink to="tutorial">Tutorial</NavLink> contiene delle brevi istruzioni
	e dei video per avviare e svolgere una lezione online. Ulteriori
	informazioni sono disponibili nelle{" "}
        <NavLink to="faq">FAQ</NavLink>.
      </p>
      <p>I docenti possono visualizzare l'elenco degli studenti autorizzati alla DAD nella pagina{" "}
          <a href="https://zeus.unich.it/studentidad/">zeus.unich.it/studentidad/</a>.
      </p>
      <h3>Contatti</h3>
      <p>
        Per eventuali problemi, potete inviare una email a
        <a href="mailto:didatticaadistanza@unich.it">
          {" "}
          didatticaadistanza@unich.it
        </a>
      </p>
    </>
  );
};

export default Home;
